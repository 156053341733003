import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {

    return (
        <footer className='footer'>
            <p className="footer__copyright">© katya daugel-dauge 2017–2024</p>
            <Link className="footer__link" to="https://www.instagram.com/keith__dd" target="_blank">inst: keith__dd</Link>
            <Link className="footer__mobile-link" to="*" target="_blank">@keith__dd</Link>
        </footer>
    );
}

export default Footer;
