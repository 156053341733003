const mainFonts = [
    {
        "title": "dype",
        "display": "DYPEDACES",
        'blank': false,
        "url": "/dype",
        "style": {
            fontFamily: 'dypedace',
            fontWeight: '100',
            fontSize: '272px',
            top: '20px',
            left: '30px'
        },
        "mobileStyle": {
            fontFamily: 'dypedace',
            fontWeight: '100',
            fontSize: '18.4vw',
            paddingTop: '2.5%'
        },
        "safariStyle" : {
            fontFamily: 'dypedace',
            fontWeight: '100',
            fontSize: '272px',
            top: '20px',
            left: '32px'
        },
        "safariMobileStyle" : {
            fontFamily: 'dypedace',
            fontWeight: '100',
            fontSize: '18.6vw',
            paddingTop: '2%'
        },
        "animation": "weight"
    },

    {
        "title": "dd grotesk",
        "display": "киса в домике would you like to киса в домике would you like to киса в домике would you like to",
        'blank': false,
        "url": "/dd-grotesk",
        "style": {
            fontFamily: 'dd-grotesk',
            fontWeight: '300',
            fontSize: '405px',
            letterSpacing: '-32.4px',
            bottom: '50px'
        },
        "mobileStyle": {
            fontFamily: 'dd-grotesk',
            fontWeight: '300',
            fontSize: '26.7vw',
            letterSpacing: '-8px',
            paddingBottom: '6%'
        },
        "safariStyle" : {
            fontFamily: 'dd-grotesk',
            fontWeight: '300',
            fontSize: '405px',
            letterSpacing: '-32.4px',
            bottom: '90px',
            left: '15px'
        },
        "safariMobileStyle" : {
            fontFamily: 'dd-grotesk',
            fontWeight: '300',
            fontSize: '26.8vw',
            letterSpacing: '-8px',
            paddingBottom: '11.5%'
        },
        "animation": "slide"
    },

    {
        "title": "sleb",
        "display": "nautro vse prosnulis sleb got ya swan on the wind please nautro vse prosnulis sleb got ya swan on the wind please",
        'blank': false,
        "url": "/sleb",
        "style": {
            fontFamily: 'sleb',
            fontWeight: '400',
            fontSize: '413px',
            bottom: '90px',
            left: '30px'
        },
        "mobileStyle": {
            fontFamily: 'sleb',
            fontWeight: '400',
            fontSize: '28.168vw',
            paddingBottom: '12.5%'
        },
        "safariStyle" : {
            fontFamily: 'sleb',
            fontWeight: '400',
            fontSize: '413px',
            bottom: '90px',
            left: '35px'
        },
        "safariMobileStyle" : {
            fontFamily: 'sleb',
            fontWeight: '400',
            fontSize: '28.2vw',
            paddingBottom: '13%'
        },
        "animation": "slide"
    },

    {
        "title": "auff",
        "display": "fuck polar auff привет ты кто такой миу fuck polar auff привет ты кто такой миу fuck polar auff привет ты кто такой миу",
        'blank': false,
        "url": "/auff",
        "style": {
            fontFamily: 'auff',
            fontWeight: '400',
            fontSize: '350px',
            bottom: '60px',
            left: '30px'
        },
        "mobileStyle": {
            fontFamily: 'auff',
            fontWeight: '400',
            fontSize: '23.88vw',
            paddingBottom: '8.5%'
        },
        "safariStyle" : {
            fontFamily: 'auff',
            fontWeight: '400',
            fontSize: '350px',
            bottom: '90px',
            left: '35px'
        },
        "safariMobileStyle" : {
            fontFamily: 'auff',
            fontWeight: '400',
            fontSize: '23.88vw',
            paddingBottom: '12.5%'
        },
        "animation": "slide"
    },

    {
        "title": "gridsky",
        "display": "DESIRE BIG GRID SIDE RED GIBSI BESIDE GREESE DRIGGI GRIBSER DESIRE BIG GRID SIDE RED GIBSI BESIDE GREESE DRIGGI GRIBSER",
        'blank': false,
        "url": "/gridsky",
        "style": {
            fontFamily: 'gridsky',
            fontWeight: 'normal',
            fontSize: '295px',
            bottom: '17px',
            left: '30px'
        },
        "mobileStyle": {
            fontFamily: 'gridsky',
            fontWeight: 'normal',
            fontSize: '20vw',
            paddingBottom: '2.5%'
        },
        "safariStyle" : {
            fontFamily: 'gridsky',
            fontWeight: 'normal',
            fontSize: '290px',
            bottom: '40px',
            left: '30px'
        },
        "safariMobileStyle" : {
            fontFamily: 'gridsky',
            fontWeight: 'normal',
            fontSize: '20vw',
            paddingBottom: '5.7%'
        },
        "animation": "slide"
    },

    {
        "title": "shooot",
        "display": "SUCKS LOL KEK SHIT SHOOOT YOU BITCH LESS HOT LUCK VICE SHITKISS SHOCK KOKS THE SKOUTKICK SESSION NUSE BLET SUCKS LOL KEK",
        'blank': false,
        "url": "/shooot",
        "style": {
            fontFamily: 'shooot',
            fontWeight: 'normal',
            fontSize: '297px',
            bottom: '20px',
            left: '30px'
        },
        "mobileStyle": {
            fontFamily: 'shooot',
            fontWeight: 'normal',
            fontSize: '20vw',
            paddingBottom: '2.5%'
        },
        "safariStyle" : {
            fontFamily: 'shooot',
            fontWeight: 'normal',
            fontSize: '290px',
            bottom: '40px',
            left: '35px'
        },
        "safariMobileStyle" : {
            fontFamily: 'shooot',
            fontWeight: 'normal',
            fontSize: '20vw',
            paddingBottom: '5.8%'
        },
        "animation": "slide"
    },

    {
        "title": "la skale",
        "display": "SKATEBLANT TRAP LA SKALE THERE BANAN LANES BAR SAKENS THEN PLAE NEBRASKA RAB PEPE SKATEBLANT TRAP LA SKALE THERE",
        'blank': false,
        "url": "/la-skale",
        "style": {
            fontFamily: 'la_skale',
            fontWeight: 'normal',
            fontSize: '295px',
            bottom: '18px',
            left: '30px'
        },
        "mobileStyle": {
            fontFamily: 'la_skale',
            fontWeight: 'normal',
            fontSize: '20vw',
            paddingBottom: '2.5%'
        },
        "safariStyle" : {
            fontFamily: 'la_skale',
            fontWeight: 'normal',
            fontSize: '290px',
            bottom: '35px',
            left: '30px'
        },
        "safariMobileStyle" : {
            fontFamily: 'la_skale',
            fontWeight: 'normal',
            fontSize: '20vw',
            paddingBottom: '5.5%'
        },
        "animation": "slide"
    },

    {
        "title": "chickpaw",
        "display": "andesire какая славная жизнь chickpaw kink andesire какая славная жизнь chickpaw kink andesire какая славная жизнь",
        'blank': false,
        "url": "/chickpaw",
        "style": {
            fontFamily: 'chickpaw',
            fontWeight: '400',
            fontSize: '233.277px',
            left: '30px'
        },
        "mobileStyle": {
            fontFamily: 'chickpaw',
            fontWeight: '400',
            fontSize: '15.83vw'
        },
        "safariStyle" : {
            fontFamily: 'chickpaw',
            fontWeight: '400',
            fontSize: '255px',
            left: '30px',
            top: '15px'
        },
        "safariMobileStyle" : {
            fontFamily: 'chickpaw',
            fontWeight: '400',
            fontSize: '15.83vw'
        },
        "animation": "slide"
    },

    {
        "title": "dd tekst",
        "display": "kandes grotesk супер quick brown fox kandes grotesk супер quick brown fox kandes grotesk супер quick brown fox kandes grotesk супер quick brown fox",
        'blank': false,
        "url": "/dd-tekst",
        "style": {
            fontFamily: 'dd-tekst',
            fontWeight: '400',
            fontSize: '390px',
            letterSpacing: '-7.466px',
            bottom: '40px',
            left: '15px'
        },
        "mobileStyle": {
            fontFamily: 'dd-tekst',
            fontWeight: 'normal',
            fontSize: '25.33vw',
            paddingBottom: '5%'
        },
        "safariStyle" : {
            fontFamily: 'dd-tekst',
            fontWeight: '400',
            fontSize: '410px',
            letterSpacing: '-7.466px',
            bottom: '125px',
            left: '20px'
        },
        "safariMobileStyle" : {
            fontFamily: 'dd-tekst',
            fontWeight: 'normal',
            fontSize: '25.33vw',
            paddingBottom: '14.8%'
        },
        "animation": "slide"
    },

    {
        "title": "ancor",
        "display": "ЯКОРЬ А КРАСОТА ROL СОВА НАСРАТЬ ГАГАРА ЯКОРЬ А КРАСОТА ROL СОВА НАСРАТЬ ГАГАРА ЯКОРЬ А КРАСОТА ROL СОВА НАСРАТЬ ГАГАРА ЯКОРЬ А КРАСОТА ROL СОВА НАСРАТЬ ГАГАРА",
        'blank': false,
        "url": "/ancor",
        "style": {
            fontFamily: 'ancor',
            fontWeight: '400',
            fontSize: '290px',
            letterSpacing: '-12.24px',
            top: '14px',
            left: '15px'
        },
        "mobileStyle": {
            fontFamily: 'ancor',
            fontWeight: '400',
            fontSize: '20vw',
            paddingTop: '1.7%'
        },
        "safariStyle" : {
            fontFamily: 'ancor',
            fontWeight: '400',
            fontSize: '290px',
            letterSpacing: '-12.24px',
            bottom: '18px',
            left: '25px'
        },
        "safariMobileStyle" : {
            fontFamily: 'ancor',
            fontWeight: '400',
            fontSize: '20vw',
            paddingBottom: '10px'
        },
        "animation": "slide"
    }
];

export default mainFonts;
