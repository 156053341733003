import React from 'react';
import { Link } from 'react-router-dom';
import "./NavTabMenu.css";
import { usePopupClose } from "../../hooks/usePopupClose.js";
import close_icon from '../../images/close_icon.svg';

function NavTabMenu(props) {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    })

    usePopupClose(props.isOpen, props.closeMenu);

    return (
        <div className={`navtab-menu ${props.isOpen ? 'navtab-menu_visible' : ''}`}>
            <img className='navtab-menu__close-icon' src={close_icon} alt="Х" onClick={props.closeMenu} />
            <div className="navtab-menu__links">
                <Link className="navtab-menu__link" to="/" onClick={props.closeMenu}>dypedaces</Link>
                <Link className="navtab-menu__link" to="/logotypes" onClick={props.closeMenu}>logotypes</Link>
                <Link className="navtab-menu__link" to="/typefaces" onClick={props.closeMenu}>bespoke<br />typefaces</Link>
                <Link className="navtab-menu__link" to="/about" onClick={props.closeMenu}>about</Link>
            </div>
        </div>
    )
}


export default NavTabMenu;