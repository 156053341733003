import './Shooot.css';
import React from 'react';
import FontPage from '../../FontPage/FontPage';

function Shooot(props) {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    })

    let isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;

    function Slider() {
        window.addEventListener('input', () => {
            let input = document.getElementById('slider');
            let text = document.getElementById('textarea');
            text.style.fontSize = input.value + 'px';
        })

        return (
            <>
                <input type="range" min="94" max="519" defaultValue='275' className="font-page__slider" id='slider' />
            </>
        )
    }
    return (
        <>
            <FontPage
                openNavTabMenu={props.openNavTabMenu} title='shooot' Slider={Slider}
                info='lightning never strikes twice in the same place. but what if you have many 
                lightnings? anyway, charge of electricity can cheer your design!'>
                <div className='shooot__display' id='textarea' contentEditable="true" suppressContentEditableWarning={true}>
                    <p className={isSafari ? 'shooot__variable-text shooot__safari-indent' : 'shooot__variable-text'}>SUCKS LOL KEK SHIT SHOOOT YOU BITCH LESS HOT LUCK VICE SHITKISS SHOCK KOKS THE SKOUTKICK SESSION NUSE BLET SUCKS LOL KEK SHIT SHOOOT YOU BITCH LESS HOT LUCK VICE SHITKISS SHOCK KOKS THE SKOUTKICK SESSION NUSE BLET SUCKS LOL KEK SHIT SHOOOT YOU BITCH LESS HOT LUCK VICE SHITKISS SHOCK KOKS THE SKOUTKICK SESSION</p>
                </div>
                <p className={isSafari ? 'shooot__mobile-display shooot__safari-indent' : 'shooot__mobile-display'}>SUCKS LOL KEK SHIT SHOOOT YOU BITCH LESS HOT LUCK VICE SHITKISS SKOUTKICK SESSION NUSE</p>
            </FontPage>
        </>
    );
}

export default Shooot;
