import './Main.css';
import React from 'react';
import Header from '../Header/Header';
import Font from '../Font/Font';
import Footer from '../Footer/Footer';
import fonts from '../../utils/mainFonts';

function Main(props) {

  React.useEffect(() => {
    window.scrollTo(0, 0);
})

  return (
    <>
      <Header openNavTabMenu={props.openNavTabMenu} />
      <section className="main">
        {fonts.map((el) => (
          <Font style={el.style} mobileStyle={el.mobileStyle}  safariStyle={el.safariStyle} safariMobileStyle={el.safariMobileStyle}
          display={el.display} url={el.url} animation={el.animation} key={el.title} title={el.title} />
        ))}
      </section>
      <Footer />
    </>
  );
}

export default Main;
