import './Logotypes.css';
import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import icon_1 from '../../images/logo/logo1.svg';
import icon_2 from '../../images/logo/logo2.svg';
import icon_3 from '../../images/logo/logo3.svg';
import icon_4 from '../../images/logo/logo4.svg';
import icon_5 from '../../images/logo/logo5.svg';
import icon_6 from '../../images/logo/logo6.svg';
import icon_7 from '../../images/logo/logo7.svg';
import icon_8 from '../../images/logo/logo8.svg';
import icon_9 from '../../images/logo/logo9.svg';
import icon_10 from '../../images/logo/logo10.svg';
import icon_11 from '../../images/logo/logo11.svg';
import icon_12 from '../../images/logo/logo12.svg';
import icon_13 from '../../images/logo/logo13.svg';
import icon_14 from '../../images/logo/logo14.svg';
import icon_15 from '../../images/logo/logo15.svg';
import icon_16 from '../../images/logo/logo16.svg';
import icon_17 from '../../images/logo/logo17.svg';
import icon_18 from '../../images/logo/logo18.svg';
import icon_19 from '../../images/logo/logo19.svg';
import icon_20 from '../../images/logo/logo20.svg';
import icon_21 from '../../images/logo/logo21.svg';
import icon_22 from '../../images/logo/logo22.svg';
import icon_23 from '../../images/logo/logo23.svg';
import icon_24 from '../../images/logo/logo24.svg';
import icon_25 from '../../images/logo/logo25.svg';
import icon_26 from '../../images/logo/logo26.svg';
import icon_27 from '../../images/logo/logo27.svg';
import icon_28 from '../../images/logo/logo28.svg';
import icon_29 from '../../images/logo/logo29.svg';
import icon_30 from '../../images/logo/logo30.svg';
import icon_31 from '../../images/logo/logo31.svg';
import icon_32 from '../../images/logo/logo32.svg';
import icon_33 from '../../images/logo/logo33.svg';
import icon_34 from '../../images/logo/logo34.svg';
import icon_35 from '../../images/logo/logo35.svg';
import icon_36 from '../../images/logo/logo36.svg';
import icon_37 from '../../images/logo/logo37.svg';
import icon_38 from '../../images/logo/logo38.svg';
import icon_39 from '../../images/logo/logo39.svg';
import icon_40 from '../../images/logo/logo40.svg';
import icon_41 from '../../images/logo/logo41.svg';
import icon_42 from '../../images/logo/logo42.svg';
import icon_43 from '../../images/logo/logo43.svg';
import icon_44 from '../../images/logo/logo44.svg';
import icon_45 from '../../images/logo/logo45.svg';
import icon_46 from '../../images/logo/logo46.svg';
import icon_47 from '../../images/logo/logo47.svg';
import icon_48 from '../../images/logo/logo48.svg';
import icon_49 from '../../images/logo/logo49.svg';
import icon_50 from '../../images/logo/logo50.svg';


function Logotypes(props) {

    React.useEffect(() => {
        window.scrollTo(0, 0);
        if (window.innerWidth >= 950) {
            randomImage(true);
        } else {
            randomImage(false);
        }
    })

    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min);
    }

    function randomImage(isItMobile) {
        let container = document.getElementById('container').getBoundingClientRect();
        let width = container.width;
        let height = container.height;
        let images = Array.from(document.getElementsByTagName('img'));

        images.forEach((el) => {

            el.style.top = isItMobile ? getRandomInt(65, (height - height * 0.14)) + "px" : getRandomInt(35, (height - height * 0.097)) + "px";
            el.style.right = isItMobile ? getRandomInt(0, width - width * 0.37) + "px" : getRandomInt(0, width - width * 0.55) + "px";

            // перетаскивание логотипов с помощью мышки
            el.onmousedown = function (event) {
                event.preventDefault();

                let shiftX = event.clientX - el.getBoundingClientRect().left;
                let shiftY = event.clientY - el.getBoundingClientRect().top;

                el.style.zIndex = 11;

                document.getElementById('container').append(el);

                function moveAt(pageX, pageY) {
                    el.style.left = pageX - shiftX + 'px';
                    el.style.top = pageY - shiftY - 72 + 'px';
                }

                function onMouseMove(event) {
                    moveAt(event.pageX, event.pageY);
                }

                document.addEventListener('mousemove', onMouseMove);

                document.onmouseup = function () {
                    el.style.zIndex = 8;
                    document.removeEventListener('mousemove', onMouseMove);
                    el.onmouseup = null;
                };
            };


            el.ondragstart = function () {
                return false;
            };


            // перетаскивание логотипов с помощью сенсора
            el.ontouchstart = function () {
                el.style.zIndex = 9;

                document.getElementById('container').append(el);

                function onTouchMove(event) {
                    let target = event.target;

                    el.style.left = (event.changedTouches[0].pageX - target.clientWidth / 2) + 'px';
                    el.style.top = (event.changedTouches[0].pageY - target.clientHeight / 2 - 72) + 'px';
                }

                document.addEventListener('touchmove', onTouchMove);

                el.ontouchend = function () {
                    document.removeEventListener('touchmove', onTouchMove);
                    el.ontouchend = null;
                };
            }
        })
    }

    return (
        <>
            <Header openNavTabMenu={props.openNavTabMenu} />
            <section className="logotypes" id='container'>
                <p className='logotypes__text'>you can order custom lettering for any of your ideas. for example:</p>
                <img className='logotypes__icon logotypes__wide-logo' src={icon_1} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_2} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_3} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_4} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_5} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_6} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_7} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_8} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_9} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_10} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_11} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_12} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_13} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_14} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_15} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_16} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_17} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_18} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_19} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_20} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_21} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_22} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_23} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_24} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_25} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_26} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_27} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_28} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_29} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_30} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_31} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_32} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_33} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_34} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_35} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_36} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_37} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_38} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_39} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_40} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_41} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_42} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_43} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_44} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_45} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_46} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_47} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_48} alt='icon' />
                <img className='logotypes__icon logotypes__wide-logo' src={icon_49} alt='icon' />
                <img className='logotypes__icon logotypes__high-logo' src={icon_50} alt='icon' />
            </section>
            <Footer />
        </>
    );
}

export default Logotypes;

