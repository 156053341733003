const typefacesFonts = [
    {
        "title": "bipolar",
        'blank': true,
        "display": "рихтеР биполяр гротeск richter bipolar рихтеР биполяр гротeск richter bipolar рихтеР биполяр гротeск richter bipolar",
        "url": "https://eshgruppa.com/works/bipolar-grotesque",
        "fontFamily": "bipolar",
        "style": {
            fontFamily: 'bipolar',
            fontWeight: '400',
            fontSize: '300px',
            left: '15px',
            bottom: '8px'
        },
        "mobileStyle": {
            fontFamily: 'bipolar',
            fontWeight: '400',
            fontSize: '19.8vw',
            paddingBottom: '0.2%'
        },
        "safariStyle" : {
            fontFamily: 'bipolar',
            fontWeight: '400',
            fontSize: '295px',
            left: '15px',
            bottom: '7px'
        },
        "safariMobileStyle" : {
            fontFamily: 'bipolar',
            fontWeight: '400',
            fontSize: '19.8vw'
        },
        "animation": "slide"
    },

    {
        "title": "dvorulitsa",
        'blank': true,
        "display": "ghbolec дворулица dvorulitsa мяу ghbolec дворулица dvorulitsa мяу ghbolec дворулица dvorulitsa мяу ghbolec дворулица",
        "url": "https://eshgruppa.com/works/dvorul-grotesk",
        "fontFamily": "dvorul",
        "style": {
            fontFamily: 'dvorul',
            fontWeight: '400',
            fontSize: '400px',
            letterSpacing: '-22.14px',
            bottom: '87px',
            left: '15px'
        },
        "mobileStyle": {
            fontFamily: 'dvorul',
            fontWeight: '400',
            fontSize: '26.6vw',
            letterSpacing: '-5.639px',
            paddingBottom: '11.5%'
        },
        "safariStyle" : {
            fontFamily: 'dvorul',
            fontWeight: '400',
            fontSize: '390px',
            letterSpacing: '-22.14px',
            bottom: '80px',
            left: '15px'
        },
        "safariMobileStyle" : {
            fontFamily: 'dvorul',
            fontWeight: '400',
            fontSize: '26.6vw',
            letterSpacing: '-5.639px',
            paddingBottom: '11%'
        },
        "animation": "slide"
    },

    {
        "title": "ryazandrama",
        "display": "rysantheatd рязань драматический театр приветствует вас rysantheatd рязань драматический театр приветствует вас",
        "url": "javascript: void(0)",
        "fontFamily": "ryazandrama",
        "style": {
            fontFamily: 'ryazandrama',
            fontWeight: '400',
            fontSize: '302px',
            top: '9px',
            left: '15px'
        },
        "mobileStyle": {
            fontFamily: 'ryazandrama',
            fontWeight: '400',
            fontSize: '19.73vw',
            paddingTop: '2%'
        },
        "safariStyle" : {
            fontFamily: 'ryazandrama',
            fontWeight: '400',
            fontSize: '285px',
            paddingTop: '20px',
            left: '20px',
            bottom: '14px'
        },
        "safariMobileStyle" : {
            fontFamily: 'ryazandrama',
            fontWeight: '400',
            fontSize: '19.73vw',
            paddingBottom: '10px'
        },
        "animation": "slide"
    },

    {
        "title": "mhat",
        'blank': true,
        "display": "moskovskiy мхат имени горького лес moskovskiy мхат имени горького лес moskovskiy мхат имени горького лес",
        "url": "https://www.instagram.com/p/CmeHODvLWQp/?igsh=eGJvM2YybnlxMXNp",
        "fontFamily": "mhat",
        "style": {
            fontFamily: 'mhat',
            fontWeight: '400',
            fontSize: '300px',
            letterSpacing: '-9.06px',
            bottom: '10px',
            left: '5px'
        },
        "mobileStyle": {
            fontFamily: 'mhat',
            fontWeight: '400',
            fontSize: '20vw',
            letterSpacing: '-2.308px',
            paddingBottom: '0.8%'
        },
        "safariStyle" : {
            fontFamily: 'mhat',
            fontWeight: '400',
            fontSize: '302px',
            letterSpacing: '-9.06px',
            bottom: '10px',
            left: '10px'
        },
        "safariMobileStyle" : {
            fontFamily: 'mhat',
            fontWeight: '400',
            fontSize: '20vw',
            letterSpacing: '-2.308px',
            paddingBottom: '5px'
        },
        "animation": "slide"
    },

    {
        "title": "feerique",
        'blank': true,
        "display": "feerique script свадьба пела и плясала капли вина на белой feerique script свадьба пела и плясала капли вина на белой",
        "url": "https://eshgruppa.com/works/feerique-script",
        "fontFamily": "feerique",
        "style": {
            fontFamily: 'feerique',
            fontWeight: '400',
            fontSize: '252px',
            letterSpacing: '-7.56px',
            left: '30px'
        },
        "mobileStyle": {
            fontFamily: 'feerique',
            fontWeight: '400',
            fontSize: '17vw',
            letterSpacing: '-1.926px'
        },
        "safariStyle" : {
            fontFamily: 'feerique',
            fontWeight: '400',
            fontSize: '252px',
            letterSpacing: '-7.56px',
            left: '30px'
        },
        "safariMobileStyle" : {
            fontFamily: 'feerique',
            fontWeight: '400',
            fontSize: '17vw',
            letterSpacing: '-1.926px'
        },
        "animation": "slide"
    },

    {
        "title": "nima",
        'blank': true,
        "display": "nimaoчто такое искусство? nimaoчто такое искусство? nimaoчто такое искусство? nimaoчто такое искусство?",
        "url": "https://eshgruppa.com/works/nima",
        "fontFamily": "nima",
        "style": {
            fontFamily: 'nima',
            fontWeight: '400',
            fontSize: '252px',
            letterSpacing: '-7.56px',
            left: '15px'
        },
        "mobileStyle": {
            fontFamily: 'nima',
            fontWeight: '400',
            fontSize: '17vw',
            letterSpacing: '-1.926px',
            paddingTop: '0.3%'
        },
        "safariStyle" : {
            fontFamily: 'nima',
            fontWeight: '400',
            fontSize: '252px',
            letterSpacing: '-7.56px',
            bottom: '50px',
            left: '15px'
        },
        "safariMobileStyle" : {
            fontFamily: 'nima',
            fontWeight: '400',
            fontSize: '17vw',
            letterSpacing: '-1.926px',
            paddingBottom: '5.3%'
        },
        "animation": "slide"
    },

    {
        "title": "esh",
        "display": "privetiki vsem who are here we love you privetiki vsem who are here we love you privetiki vsem who are here we love you ",
        "url": "javascript: void(0)",
        "fontFamily": "esh_v2",
        "style": {
            fontFamily: 'esh_v2',
            fontWeight: '400',
            fontSize: '400px',
            letterSpacing: '-7.86px',
            bottom: '45px',
            left: '20px'
        },
        "mobileStyle": {
            fontFamily: 'esh_v2',
            fontWeight: '400',
            fontSize: '25vw',
            letterSpacing: '-2.002px',
            paddingBottom: '4%'
        },
        "safariStyle" : {
            fontFamily: 'esh_v2',
            fontWeight: '400',
            fontSize: '400px',
            letterSpacing: '-7.86px',
            bottom: '110px',
            left: '25px'
        },
        "safariMobileStyle" : {
            fontFamily: 'esh_v2',
            fontWeight: '400',
            fontSize: '25vw',
            letterSpacing: '-2.002px',
            paddingBottom: '13%'
        },
        "animation": "slide"
    }
];

export default typefacesFonts;
