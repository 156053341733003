import './Chickpaw.css';
import React from 'react';
import FontPage from '../../FontPage/FontPage';

function Chickpaw(props) {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    })

    let isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;

    function Slider() {
        window.addEventListener('input', () => {
            let input = document.getElementById('slider');
            let text = document.getElementById('textarea');
            text.style.fontSize = input.value + 'px';
        })

        return (
            <>
                <input type="range" min="76" max="430" defaultValue='220' className="font-page__slider" id='slider' />
            </>
        )
    }

    return (
        <>
            <FontPage
                openNavTabMenu={props.openNavTabMenu} title='chickpaw' Slider={Slider}
                info='how to convert a bug into a feature? i love handwriting in ‘chicken scratch’ 
                style. so, drawing this one, i decided to leave weird endings of bezier strokes as 
                they are (as a real chick may do). this added some zest to the typeface.'>
                <div className='chickpaw__display' id='textarea' contentEditable="true" suppressContentEditableWarning={true}>
                    <p className={isSafari ? 'chickpaw__variable-text chickpaw__safari-indent' : 'chickpaw__variable-text'}>andesire какая славная жизнь chickpaw kink andesire какая славная жизнь chickpaw kink andesire какая славная</p>
                </div>
                <p className={isSafari ? 'chickpaw__mobile-display chickpaw__safari-indent' : 'chickpaw__mobile-display'}>какая славная жизнь chickpaw</p>
            </FontPage>
        </>
    );
}

export default Chickpaw;
