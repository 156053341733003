import './Header.css';
import React from 'react';
import { Link } from 'react-router-dom';

function Header(props) {

  let path = window.location.pathname;

  return (
    <header className="header">
      <Link className={path === '/' ? 'header__disable-link' : 'header__link'} to="/">dypedaces</Link>
      <div className="header__container">
        <Link className={path === '/logotypes' ? 'header__disable-link' : 'header__link'} to="/logotypes">logotypes</Link>
        <Link className={path === '/typefaces' ? 'header__disable-link' : 'header__link'} to="/typefaces">bespoke typefaces</Link>
        <Link className={path === '/about' ? 'header__disable-link' : 'header__link'} to="/about">about</Link>
      </div>
      <div className='header__hiden-icon' onClick={props.openNavTabMenu}></div>
    </header>
  );
}

export default Header;
