import './Auff.css';
import React from 'react';
import FontPage from '../../FontPage/FontPage';

function Auff(props) {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    })

    let isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;

    function Slider() {
        window.addEventListener('input', () => {
            let input = document.getElementById('slider');
            let text = document.getElementById('textarea');
            text.style.fontSize = input.value + 'px';
        })

        return (
            <>
                <input type="range" min="93" max="518" defaultValue='287' className="font-page__slider" id='slider' />
            </>
        )
    }

    return (
        <>
            <FontPage
                openNavTabMenu={props.openNavTabMenu} title='auff' Slider={Slider}
                info='maximum modularity (only two modules run) and you have a very quadratic, 
                very mono spaced typeface. on the other hand, you have a spinning effect (because 
                of different flipping of the second block) and you can turn your life upside down!'>
                <div className='auff__display' id='textarea' contentEditable="true" suppressContentEditableWarning={true}>
                    <p className={isSafari ? 'auff__variable-text auff__safari-indent' : 'auff__variable-text'}>fuck auf polar auff привет ты кто такой миу fuck polar auff привет ты кто такой миу fuck polar auff привет ты кто такой</p>
                </div>
                <p className={isSafari ? 'auff__mobile-display auff__safari-indent' : 'auff__mobile-display'}>fuck auff polar миу привет ты кто такой</p>
            </FontPage>
        </>
    );
}

export default Auff;
