import './Grotesk.css';
import React from 'react';
import FontPage from '../../FontPage/FontPage';

function Grotesk(props) {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    })

    let isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;


    function Slider() {
        window.addEventListener('input', () => {
            let input = document.getElementById('slider');
            let text = document.getElementById('textarea');
            text.style.fontSize = input.value + 'px';
        })

        return (
            <>
                <input type="range" min="83" max="518" defaultValue='310' className="font-page__slider" id='slider' />
            </>
        )
    }

    return (
        <>
            <FontPage
                openNavTabMenu={props.openNavTabMenu} title='dd grotesk' Slider={Slider}
                info='super weird artistic typeface, that thinks about himself much. too much specialitates 
                in onething, too light for text font... so i don’t know how you can use it, but 
                want to see it very much!'>
                <div className='grotesk__display' id='textarea' contentEditable="true" suppressContentEditableWarning={true}>
                    <p className={isSafari ? 'grotesk__variable-text grotesk__safari-indent' : 'grotesk__variable-text'}>Киса в домике would you like to Киса в домике would you like to Киса в домике would you like to Киса в домике would you like to Киса в домике would you like to Киса в домике would you like to</p>
                </div>
                <p className={isSafari ? 'grotesk__mobile-display grotesk__safari-indent' : 'grotesk__mobile-display'}>Киса в домике would you like</p>
            </FontPage>
        </>
    );
}

export default Grotesk;
