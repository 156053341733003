import './FontPage.css';
import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

function FontPage(props) {
    //const [isItDype, setDype] = React.useState(window.location.pathname === '/dype' ? true : false);

    // window.addEventListener('input', () => {
    //     let input = document.getElementById('slider');
    //     let textarea = props.textarea;
    //     //let firstText = document.getElementById('first-textarea');
    //     //let secondText = document.getElementById('second-textarea');
    //     textarea.style.fontSize = input.value + 'px';
    //     //secondText.style.fontSize = input.value + 'px';
    // })

    //<textarea className={isItDype ? 'font-page__display dype' : 'font-page__display'} style={props.style}
    // defaultValue={props.display} id='first-textarea' ></textarea>
    // <textarea className={isItDype ? 'font-page__display dype' : 'font-page__display'} style={props.style}
    //     defaultValue={props.display} id='second-textarea' ></textarea>
    // <p className={isItDype ? 'font-page__mobile-display dype' : 'font-page__mobile-display'}
    //     style={props.style}>{props.display}</p>
    // <p className={isItDype ? 'font-page__mobile-display dype' : 'font-page__mobile-display'}
    //     style={props.style}>{props.display}</p>
    // <p className={isItDype ? 'font-page__mobile-display dype' : 'font-page__mobile-display'}
    //     style={props.style}>{props.display}</p>
    // <p className={isItDype ? 'font-page__mobile-display dype' : 'font-page__mobile-display'}
    //     style={props.style}>{props.display}</p>

    //<input type="range" min="30" max="800" defaultValue='269' className="font-page__slider" id='slider' />

    // style fontFamily display - убрать эти пропсы
 

    return (
        <>
            <Header openNavTabMenu={props.openNavTabMenu} />
            <section className="font-page">
                <div className='font-page__container' id='container'>
                    {props.children}
                </div>
                <div className='font-page__info-container'>
                    <h2 className='font-page__text'>{props.title}</h2>
                    <props.Slider />
                    <p className='font-page__text'>$20</p>
                    <p className='font-page__info'>{props.info}</p>
                </div>
                <button className='font-page__button'>buy</button>
            </section>
            <Footer />
        </>
    );
}

export default FontPage;
