import './Ancor.css';
import React from 'react';
import FontPage from '../../FontPage/FontPage';

function Ancor(props) {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    })

    let isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;

    function Slider() {
        window.addEventListener('input', () => {
            let input = document.getElementById('slider');
            let text = document.getElementById('textarea');
            text.style.fontSize = input.value + 'px';
        })

        return (
            <>
                <input type="range" min="94" max="519" defaultValue='293' className="font-page__slider" id='slider' />
            </>
        )
    }

    return (
        <>
            <FontPage
                openNavTabMenu={props.openNavTabMenu} title='ancor' Slider={Slider}
                info='very unusual antiqua, in which serifs become stems. and white space, generally, 
                behaves waywardly. maybe it is the most brutalist antiqua in the world.'>
                <div className='ancor__display' id='textarea' contentEditable="true" suppressContentEditableWarning={true}>
                    <p className={isSafari ? 'ancor__variable-text ancor__safari-indent' : 'ancor__variable-text'}>ЯКОРЬ А КРАСОТА ROL СОВА НАСРАТЬ ГАГАРА ЯКОРЬ А КРАСОТА ROL СОВА НАСРАТЬ ГАГАРА ЯКОРЬ А КРАСОТА ROL СОВА НАСРАТЬ ГАГАРА </p>
                </div>
                <p className={isSafari ? 'ancor__mobile-display ancor__safari-indent' : 'ancor__mobile-display'}>ЯКОРЬ А КРАСОТА ROL СОВА НАСРАТЬ</p>
            </FontPage>
        </>
    );
}

export default Ancor;
