import './LaSkale.css';
import React from 'react';
import FontPage from '../../FontPage/FontPage';

function LaSkale(props) {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    })

    let isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;

    function Slider() {
        window.addEventListener('input', () => {
            let input = document.getElementById('slider');
            let text = document.getElementById('textarea');
            text.style.fontSize = input.value + 'px';
        })

        return (
            <>
                <input type="range" min="94" max="519" defaultValue='276' className="font-page__slider" id='slider' />
            </>
        )
    }

    return (
        <>
            <FontPage
                openNavTabMenu={props.openNavTabMenu} title='la skale' Slider={Slider}
                info='square inside, round outside. it’s not so clear how sense of 60th and some 
                folk aesthetic may connect in one typeface.'>
                <div className='la-skale__display' id='textarea' contentEditable="true" suppressContentEditableWarning={true}>
                    <p className={isSafari ? 'la-skale__variable-text la-skale__safari-indent' : 'la-skale__variable-text'}>SKATEBLANT TRAP LA SKALE THERE BANAN LANES BAR SAKENS THEN PLAE NEBRASKA RAB PEPE SKATEBLANT TRAP LA SKALE THERE SKATEBLANT TRAP LA SKALE THERE BANAN LANES BAR SAKENS THEN PLAE NEBRASKA RAB PEPE SKATEBLANT TRAP LA SKALE</p>
                </div>
                <p className={isSafari ? 'la-skale__mobile-display la-skale__safari-indent' : 'la-skale__mobile-display'}>SKATEBLANT TRAP LA SKALE THERE BANAN LANES BAR SAKENS THEN PLAE</p>
            </FontPage>
        </>
    );
}

export default LaSkale;
