import './Typefaces.css';
import React from 'react';
import Header from '../Header/Header';
import Font from '../Font/Font';
import Footer from '../Footer/Footer';
import fonts from '../../utils/typefacesFonts';
import { Link } from 'react-router-dom';

function Typefaces(props) {

  React.useEffect(() => {
    window.scrollTo(0, 0);
  })

  return (
    <>
      <Header openNavTabMenu={props.openNavTabMenu} />
      <section className="typefaces">
        <p className="typefaces__mobile-text">you can order bespoke typeface for any of your movings, it may look like this:</p>
        {fonts.map((el) => (
          <Font title={el.title} style={el.style} mobileStyle={el.mobileStyle} display={el.display} url={el.url} blank={el.blank}
            animation={el.animation} safariStyle={el.safariStyle} key={el.title} safariMobileStyle={el.safariMobileStyle} />
        ))}
        <p className="typefaces__text">all this typefaces made with <Link className='typefaces__line' to="https://eshgruppa.com/" target="_blank">esh gruppa.</Link></p>
      </section>
      <Footer />
    </>
  );
}

export default Typefaces;
