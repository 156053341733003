import './Sleb.css';
import React from 'react';
import FontPage from '../../FontPage/FontPage';

function Sleb(props) {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    })

    let isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;

    function Slider() {
        window.addEventListener('input', () => {
            let input = document.getElementById('slider');
            let text = document.getElementById('textarea');
            text.style.fontSize = input.value + 'px';
        })

        return (
            <>
                <input type="range" min="93" max="518" defaultValue='291' className="font-page__slider" id='slider' />
            </>
        )
    }

    return (
        <>
            <FontPage
                openNavTabMenu={props.openNavTabMenu} title='sleb' Slider={Slider}
                info='like a swan trembles in the wind, broken hairlines merged with strong 
                bodonied stems and you catch a very speсial feeling about it.'>
                <div className='sleb__display' id='textarea' contentEditable="true" suppressContentEditableWarning={true}>
                    <p className={isSafari ? 'sleb__variable-text sleb__safari-indent' : 'sleb__variable-text'}>nautro vse prosnulis sleb got ya swan on the wind please nautro vse prosnulis sleb got ya swan on the wind please nautro vse prosnulis sleb got ya swan on the wind please nautro vse prosnulis sleb got ya swan on the wind please nautro vse prosnulis sleb got ya swan on the</p>
                </div>
                <p className={isSafari ? 'sleb__mobile-display sleb__safari-indent' : 'sleb__mobile-display'}>nautro vse prosnulis sleb got ya swan on the wind please</p>
            </FontPage>
        </>
    );
}

export default Sleb;
