import './Dype.css';
import React from 'react';
import FontPage from '../../FontPage/FontPage';

function Dype(props) {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    })

    function Slider() {
        window.addEventListener('input', () => {
            let input = document.getElementById('slider');
            let text = document.getElementById('textarea');
            text.style.fontSize = input.value + 'px';
        })

        return (
            <>
                <input type="range" min="30" max="800" defaultValue='268' className="font-page__slider" id='slider' />
            </>
        )
    }

    return (
        <>
            <FontPage
                openNavTabMenu={props.openNavTabMenu} title='dype' Slider={Slider}
                info='what do you know about floral style? you can fully dive into the plants 
                with dype typeface! four weights for any need. vanishing thin, light, bold or 
                deeeeep black! your plants may curl all over the world!'>
                <div className='dype__display' id='textarea' contentEditable="true" suppressContentEditableWarning={true}>
                    <p className='dype__variable-text'>DYPEDACES</p>
                    <p className='dype__variable-text'>DYPEDACES</p>
                    <p className='dype__variable-text'>DYPEDACES</p>
                    <p className='dype__variable-text'>DYPEDACES</p>
                </div>
                <p className='dype__mobile-display'>DYPEDACES</p>
                <p className='dype__mobile-display'>DYPEDACES</p>
                <p className='dype__mobile-display'>DYPEDACES</p>
                <p className='dype__mobile-display'>DYPEDACES</p>
            </FontPage>
        </>
    );
}

export default Dype;
