import './Gridsky.css';
import React from 'react';
import FontPage from '../../FontPage/FontPage';

function Gridsky(props) {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    })

    let isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;

    function Slider() {
        window.addEventListener('input', () => {
            let input = document.getElementById('slider');
            let text = document.getElementById('textarea');
            text.style.fontSize = input.value + 'px';
        })

        return (
            <>
                <input type="range" min="94" max="519" defaultValue='276' className="font-page__slider" id='slider' />
            </>
        )
    }

    return (
        <>
            <FontPage
                openNavTabMenu={props.openNavTabMenu} title='gridsky' Slider={Slider}
                info='what happens if peaky serifs penetrate tough stems? white space gets inside 
                the letter, and by other five serifs the letter tightly holds on the surface. so 
                you immediately get a modular grid, even if not in the layout yet, it’s already 
                there in letters.'>
                <div className='gridsky__display' id='textarea' contentEditable="true" suppressContentEditableWarning={true}>
                    <p className={isSafari ? 'gridsky__variable-text gridsky__safari-indent' : 'gridsky__variable-text'}>DESIRE BIG GRID SIDE RED GIBSI BESIDE GREESE DRIGGI GRIBSER DESIRE BIG GRID SIDE RED GIBSI BESIDE GREESE DRIGGI GRIBSER DESIRE BIG GRID SIDE RED GIBSI BESIDE GREESE DRIGGI GRIBSER </p>
                </div>
                <p className={isSafari ? 'gridsky__mobile-display gridsky__safari-indent' : 'gridsky__mobile-display'}>DESIRE BIG GRID SIDE RED GIBSI BESIDE GREESE DRIGGI</p>
            </FontPage>
        </>
    );
}

export default Gridsky;
