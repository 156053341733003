import './About.css';
import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import image from '../../images/about__img.png';

function About(props) {

  React.useEffect(() => {
    window.scrollTo(0, 0);
})

  return (
    <>
      <Header openNavTabMenu={props.openNavTabMenu} />
      <section className="about">
        <p className='about__text'>hi, my name is katya daugel-dauge. i love to create wild ugly weird bizarre
          display typefaces. and letterings, also known as logotypes. so you can use my stuff in your beautiful
          fancy nice funny cute projects. i’ll be happy if you can do that. so, peace and love 🖖</p>
        <div className='about__contacts-container'>
          <Link className='about__contact' to="https://www.instagram.com/keith__dd" target="_blank">inst: keith__dd</Link>
          <Link className='about__contact' to="mailto:zantreia@gmail.com"><span className='about__desktop-contact'>mail: </span>zantreia@gmail.com</Link>
          <Link className='about__contact' to="https://www.behance.net/daugel-dauge" target="_blank">behance.net/daugel-dauge</Link>
        </div>
        <img className='about__image' src={image} alt='фотография' />
      </section>
      <Footer />
    </>
  );
}

export default About;
