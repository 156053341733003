import React from 'react';
import "./NotFound.css";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

function NotFound(props) {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <>
        <Header openNavTabMenu={props.openNavTabMenu} />
        <section className="not-found">
                <h2 className="not-found__title">404</h2>
        </section>
        <Footer />
        </>
    )
}

export default NotFound;
