import './DdTekst.css';
import React from 'react';
import FontPage from '../../FontPage/FontPage';

function DdTekst(props) {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    })

    let isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;

    function Slider() {
        window.addEventListener('input', () => {
            let input = document.getElementById('slider');
            let text = document.getElementById('textarea');
            text.style.fontSize = input.value + 'px';
        })

        return (
            <>
                <input type="range" min="95" max="519" defaultValue='276' className="font-page__slider" id='slider' />
            </>
        )
    }

    return (
        <>
            <FontPage
                openNavTabMenu={props.openNavTabMenu} title='dd tekst' Slider={Slider}
                info='text version of dd grotesk. it became calmer, bolder, narrower and more readable. 
                so now you can use it in long texts and small sizes! by the way, this website is using it 
                right now.'>
                <div className='dd-tekst__display' id='textarea' contentEditable="true" suppressContentEditableWarning={true}>
                    <p className={isSafari ? 'dd-tekst__variable-text dd-tekst__safari-indent' : 'dd-tekst__variable-text'}>kandes grotesk супер quick brown fox kandes grotesk супер quick brown fox kandes grotesk супер quick brown fox kandes grotesk</p>
                </div>
                <p className={isSafari ? 'dd-tekst__mobile-display dd-tekst__safari-indent' : 'dd-tekst__mobile-display'}>grotesk супер quick brown fox</p>
            </FontPage>
        </>
    );
}

export default DdTekst;
