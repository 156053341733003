import './App.css';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CurrentUserContext from '../../contexts/CurrentUserContext.js';
import Main from '../Main/Main';
import About from '../About/About';
import Typefaces from '../Typefaces/Typefaces';
import Logotypes from '../Logotypes/Logotypes';
import NotFound from '../NotFound/NotFound';
import NavTabMenu from '../NavTabMenu/NavTabMenu';
import Dype from '../Pages/Dype/Dype';
import Grotesk from '../Pages/Grotesk/Grotesk';
import Sleb  from '../Pages/Sleb/Sleb';
import Auff from '../Pages/Auff/Auff';
import Gridsky from '../Pages/Gridsky/Gridsky';
import Shooot from '../Pages/Shooot/Shooot';
import LaSkale from '../Pages/LaSkale/LaSkale';
import Chickpaw from '../Pages/Chickpaw/Chickpaw';
import DdTekst from '../Pages/DdTekst/DdTekst';
import Ancor from '../Pages/Ancor/Ancor';

function App() {

  let currentUser = {};
  const [isNavTabMenuOpen, setNavTabMenuOpen] = React.useState(false);

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <div className="page">
        <Routes>
          <Route path="/" element={<Main
            openNavTabMenu={() => { setNavTabMenuOpen(!isNavTabMenuOpen); }} />} />
          <Route path="/about" element={<About
            openNavTabMenu={() => { setNavTabMenuOpen(!isNavTabMenuOpen); }} />} />
          <Route path="/typefaces" element={<Typefaces
            openNavTabMenu={() => { setNavTabMenuOpen(!isNavTabMenuOpen); }} />} />
          <Route path="/logotypes" element={<Logotypes
            openNavTabMenu={() => { setNavTabMenuOpen(!isNavTabMenuOpen); }} />} />
          <Route path="/dype" element={<Dype
            openNavTabMenu={() => { setNavTabMenuOpen(!isNavTabMenuOpen); }}
          />} />
          <Route path="/dd-grotesk" element={<Grotesk
            openNavTabMenu={() => { setNavTabMenuOpen(!isNavTabMenuOpen); }}
          />} />
          <Route path="/sleb" element={<Sleb
            openNavTabMenu={() => { setNavTabMenuOpen(!isNavTabMenuOpen); }}
          />} />
          <Route path="/auff" element={<Auff
            openNavTabMenu={() => { setNavTabMenuOpen(!isNavTabMenuOpen); }}
          />} />
          <Route path="/gridsky" element={<Gridsky
            openNavTabMenu={() => { setNavTabMenuOpen(!isNavTabMenuOpen); }}
          />} />
          <Route path="/shooot" element={<Shooot
            openNavTabMenu={() => { setNavTabMenuOpen(!isNavTabMenuOpen); }}
          />} />
          <Route path="/la-skale" element={<LaSkale
            openNavTabMenu={() => { setNavTabMenuOpen(!isNavTabMenuOpen); }}
          />} />
          <Route path="/chickpaw" element={<Chickpaw
            openNavTabMenu={() => { setNavTabMenuOpen(!isNavTabMenuOpen); }}
          />} />
          <Route path="/dd-tekst" element={<DdTekst
            openNavTabMenu={() => { setNavTabMenuOpen(!isNavTabMenuOpen); }}
          />} />
          <Route path="/ancor" element={<Ancor
            openNavTabMenu={() => { setNavTabMenuOpen(!isNavTabMenuOpen); }}
          />} />
          <Route path="*" element={<NotFound
            openNavTabMenu={() => { setNavTabMenuOpen(!isNavTabMenuOpen); }}
          />} />
        </Routes>
        <NavTabMenu isOpen={isNavTabMenuOpen} closeMenu={() => { setNavTabMenuOpen(false); }} />
      </div>
    </CurrentUserContext.Provider>
  );
}



export default App;
