import './Font.css';
import React from 'react';
import { Link } from 'react-router-dom';

function Font(props) {

    let isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;

    //let elemenet = document.getElementById(`${props.title}`);
    //console.log(elemenet);

    //elemenet.addEventListener('mouseenter', onMoseEnter);

    //function onMoseEnter() {
        //console.log(elemenet);
        // elemenet.classList.add('infinite-slide');
        // setTimeout(() => {
        //     elemenet.classList.remove('infinite-slide');
        // }, 25000);
    //}

    //onMouseEnter={(e) => e.currentTarget.classList.add('bounce') } 
    //onAnimationEnd={(e) => e.currentTarget.classList.remove('bounce')}


    //onMouseEnter={() => this.classList.add('infinite-slide')}

    //${props.animation} infinite-slide

    return (
        <Link className="font" to={props.url} target={props.blank ? '_blank' : ''}>
            <h3 className='font__desktop-title' >{props.title}</h3>
            <div className="font__container">
                <h3 className='font__title' >{props.title}</h3>
                <p className={`font__display ${props.animation}`} 
                style={isSafari ? props.safariStyle : props.style} >{props.display}</p>
                <p className={`font__mobile-display ${props.animation}`} 
                style={isSafari ? props.safariMobileStyle : props.mobileStyle} >{props.display}</p>
            </div>
            <h3 className='font__mobile-title' >{props.title}</h3>
        </Link>
    );
}

export default Font;
